// cree moi des base url
// const BaseUrl="http://localhost:8000/api/";
// const DemandeUrl = "http://localhost:8000/images_demandes";
// const ServiceUrl = "http://localhost:8000/images_services";
const BaseUrl="https://ud-freelance.biz/back-up/api/";
const DemandeUrl = "https://ud-freelance.biz/back-up/public/images_demandes";
const ServiceUrl = "https://ud-freelance.biz/back-up/public/images_services";




export { BaseUrl, DemandeUrl, ServiceUrl };
